<template>
  <div class="comment">
    <div class="title">
      <h1><i class="el-icon-chat-line-square"></i>评论{{ commentList.length }}</h1>
    </div>
    <div class="pubCom">
      <div class="user">
        <img v-lazy="photo" alt="" v-if="photo">
        <img src="../../public/images/toux.jpg" v-else alt="">
      </div>
      <div class="pubIpt">
        <textarea v-model="content" ref="textarea" @blur="textareaBlur" @focus="textareaFocu" name="" id="" cols="30"
          rows="10" placeholder="请勿发表不良言论"></textarea>
        <div class="submit" ref="SubBtn">
          <button @click="pubComment">评论</button>
        </div>
      </div>

    </div>

    <!-- 评论列表 -->
    <div class="commentList" v-for="(item, index) in commentList" :key="index">
      <div class="userPhoto">
        <img :src="item.aut_photo" alt="" v-if="item.aut_photo">
        <img src="../../public/images/toux.jpg" v-else alt="">
      </div>
      <div class="commentInfo">
        <div class="username">
          <span>{{ item.aut_name }}</span>
          <!-- <span>[{{item.u_id}}]</span> -->
          <span>{{ item.pubdata | dataFormat }}</span>
        </div>
        <div class="sentence">
          <p>{{ item.content }}</p>
          <p v-if="userId === item.u_id || manId === 0 || manId === 9" @click="delComment(item.id)">删除评论</p>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import { getComments, delComment, addComment } from '../API/comments.js'
export default {
  name: 'comment',
  props: ['queId'],
  data() {
    return {
      commentList: [],
      photo: this.$store.state.userInfo.photo,
      commentPhoto: null,
      content: '',
      userInfo: this.$store.state.userInfo,
      isDelComment: false,
      manId: this.$store.state.userInfo.uid,
      userId: this.$store.state.userInfo.id

    }
  },
  methods: {
    textareaFocu() {
      this.$refs.textarea.style = 'height:100%'
    },
    textareaBlur() {
      this.$refs.textarea.style = ''
      this.$refs.SubBtn.style = 'align-items:end;padding:0 5px 10px 0;'
    },
    // 发表评论
    async pubComment() {
      var obj = {
        t_id: this.queId,
        content: this.content,
        parent_id: null
      }
      const that = this
      if (JSON.stringify(this.userInfo) !== '{}') {
        if (this.content === '') {
          this.$message({
            type: 'error',
            message: '还未输入评论'
          })
        } else {
          const { data: res } = await addComment(obj)
          if (res.status === 0) {
            // this.commentList = [...this.commentList]
            this.commentList.unshift(res.data)
            this.getComment()
            this.$message({
              type: 'success',
              message: res.message
            })
          } else {
            this.$message({
              type: 'error',
              message: res.message
            })
          }
          this.content = ''
        }
      } else {
        this.$message({
          type: 'error',
          message: '登录后再来发表评论哦'
        })
        setTimeout(function () {
          that.$router.push({
            path: '/login'
          })
        }, 2000)
      }
    },
    // 获取文章评论
    async getComment() {
      const { data: res } = await getComments({ id: this.queId })
      if (res.status === 0) {
        //   时间最短在前
        this.commentList = res.data
      }
    },
    // 删除文章评论
    async delComment(id) {
      console.log(id)
      const { data: res } = await delComment({ qId: this.queId, cId: id })
      if (res.status === 0) {
        this.commentList.shift(res.data)
        this.$message({
          type: 'success',
          message: res.message
        })
      } else {
        this.$message({
          type: 'error',
          message: res.message
        })
      }
    }
    // isdelShow () {
    //   const manId = this.$store.state.userInfo.uid
    //   const userId = this.$store.state.userInfo.id
    //   console.log(userId)
    //   // 管理员可以删除所有评论,发布者只能删除自己评论
    //   console.log(this.commentList)
    //   this.commentList.map((item, index) => {
    //     if (item.u_id === userId) {
    //       this.isDelComment = true
    //     }
    //   })
    //   if (manId === 0 || manId === 9) {
    //     this.isDelComment = true
    //   } else {
    //     this.isDelComment = false
    //   }
    // }
  },
  created() {
    this.getComment()
  },
  watch: {
    queId(newId) {
      this.queId = newId
      this.getComment()
    }
  }
}
</script>

<style lang="less" scoped>
.comment {
  width: 90%;
  margin: 50px auto 0;

  .title {

    h1 {
      text-align: left;
      font-size: 24px;
    }
  }

  .pubCom {
    width: 95%;
    margin: 50px auto;
    display: flex;

    .user {
      width: 43px;
      height: 40px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    .pubIpt {
      display: flex;
      align-items: center;
      padding-left: 8px;
      width: 100%;
      position: relative;
      background: rgba(245, 246, 247, 0.8);

      textarea {
        display: block;
        width: 100%;
        background: rgba(248, 249, 251, 0.8);
        border: none;
        padding: 4px 12px;
        border-radius: 4px;
        resize: none;
        height: 40px;
        font-size: 14px;
        line-height: 22px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-transition: height .3s ease-in-out;
        transition: height .3s ease-in-out;
        font-family: "SF Pro Display", Roboto, Noto, Arial, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", sans-serif;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        outline: none;
      }

      .submit {
        height: 100%;
        display: flex;
        align-items: center;

        button {
          display: block;
          width: 72px;
          height: 32px;
          color: rgb(37, 34, 34);
          background: #e1e2e6;
          border-radius: 16px;
          font-size: 14px;
          text-align: center;
          line-height: 32px;
          border: none;
          cursor: pointer;
        }

        button:hover {
          background: #FF3553;
          color: #fff
        }
      }
    }

  }

  .commentList {
    width: 90%;
    display: flex;
    margin: 30px auto;

    .userPhoto {
      width: 30px;
      height: 30px;
      border-radius: 50%;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    .commentInfo {
      width: 100%;
      display: flex;
      flex-direction: column;

      .username {

        span {
          padding: 0 10px;
          font-size: 14px;
        }
      }

      .sentence {
        padding: 10px 0 0 15px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #e8e8ed;

        p {
          font-size: 16px;
        }

        p:nth-child(2) {
          cursor: pointer;
        }

        p:nth-child(2):hover {
          color: #FF3553;
        }
      }
    }
  }
}
</style>
