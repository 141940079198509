<template>

  <div class="QueDetail">

    <Head></Head>
    <div class="info">

      <div class="back">
        <router-link to="/user/userQuestion">
          <span @click="backSch">返回</span>
        </router-link>
        <em>/</em>
        <span>问答交流详情</span>
      </div>

      <h1>{{ queInfo.title }}</h1>
      <div class="bar">
        <ul>
          <li>{{ queInfo.pubdata }}</li>
          <li>来源：{{ queInfo.author }} [ID:{{queInfo.aut_id}}] <em>/</em>   {{queInfo.department}}</li>
          <li>浏览:{{ queInfo.view }}</li>
          <li><div class="tag">{{queInfo.tag}}</div></li>
        </ul>
      </div>
    </div>
    <!-- <div class="content">
      <p>{{ queInfo.content }}</p>
    </div> -->
    <!-- <div class="content">
        <p v-html="queInfo.content"></p>
    </div> -->
    <div class="ql-snow">
          <div class="ql-editor">
            <div class="content">
              <div v-html="queInfo.content"></div>
            </div>
          </div>
    </div>
   
    <div class="action-bar">
      <button @click="tolike"><i class="el-icon-thumb"></i> 赞同{{ queInfo.likes }}</button>

      <button @click="share"><i class="el-icon-share"></i> 分享</button>

      <button @click="Collect"><i class="el-icon-star-off"></i> 收藏</button>
    </div>

    <Comment :queId="queId"></Comment>
    
  </div>
  <!-- <Foot></Foot> -->
</template>

<script>
import { getDetailQues, unLikeQue, likeQue, getQueLikes } from '../../API/Question.js'
import {addCollect,delCollect} from '../../API/Collect.js'
import {getUserInfo,getDep} from '../../API/User.js'
import head from '../head.vue'
import comments from '../comments.vue'
import foot from '../../views/foot.vue'
export default {
  name: 'questionDetail',
  props: ['queId'],
  data() {
    return {
      queInfo: {},
      userId: this.$store.state.userInfo.id,
      userDep:''
    }
  },
  methods: {
    getDetail() {
      // var id = parseInt(this.$route.params.queId)
      this.$nextTick(async () => {
        var form ={id:this.userId}
        const { data: res } = await getDetailQues(this.queId)
        
        
        if (res.status === 0) {
          
          this.queInfo = res.data[0]
          // console.log(this.queInfo,'queInfo front')
        } else {
          this.$message({
            type: 'error',
            message: res.message
          })
        }
      })
      //   console.log(this.$route.params.schArtId)
    },
    getQueLikes() {
      // var id = parseInt(this.$route.params.queId)
      this.$nextTick(async () => {
        const { data: res } = await getQueLikes(this.queId)
        // console.log(res)
        if (res.status === 0) {
          // console.warn(res.data[0])
          this.queInfo.likes = res.data[0].likes;
        } else {
          this.$message({
            type: 'error',
            message: res.message
          })
        }
      })
      //   console.log(this.$route.params.schArtId)
    },
    backSch() {
      this.$emit('showQueInfo', true)
      this.$router.back(-1)
    },
    async share() {
      // 获取当前页面的URL
      const currentUrl = window.location.href;

      try {
        // 尝试使用navigator.clipboard API复制URL到剪贴板
        await navigator.clipboard.writeText(currentUrl);

        // 复制成功后的提示，这里简单使用alert作为示例，实际项目中可能希望用更优雅的方式，如弹出提示框组件
        alert('已经复制链接：' + currentUrl);
      } catch (error) {
        // 如果用户拒绝权限或者API不被支持，这里可以处理错误
        console.error('无法复制文本: ', error);
        alert('复制失败，请手动复制链接：' + currentUrl);
      }
    },
    async tolike() {
      try {
        // 先检查用户当前的点赞状态
        var form = { queId: this.queId }
        // const likeStatus = await getMyLike(form);
        const likeStatus = 0
        // 这里是无限点赞模式，只能够点赞，不能够取消点赞
        // console.warn('likestatus',likeStatus)
        if (likeStatus.data === 1) {
          // 用户未点赞，执行点赞操作
          const { data: res } = await unLikeQue(form)
          // 点赞成功后的反馈，例如刷新页面或更新UI
          // console.log('取消点赞成功');
        } else {
          // 用户未点赞，执行点赞操作
          const { data: res } = await likeQue(form)
          // 点赞成功后的反馈，例如刷新页面或更新UI
          // console.log('点赞成功');
        } 
        this.getQueLikes();
      } catch (error) {
        // 处理可能出现的错误
        console.error('点赞时出错:', error);
      }
    },
    async Collect() {
      try {

        var form = { user_id: this.userId,
                     que_id:this.queInfo.id
        }
          const { data: res } = await addCollect(form)
          if(res.status==0){
            this.$message({
              type: 'success',
              showClose: true,
              duration:3000,
              message: res.message
            })
          }else if(res.status==2){
            this.$message({
              type: 'info',
              showClose: true,
              duration:3000,
              message: res.message
            })
          }else{
            this.$message({
              type: 'error',
              showClose: true,
              duration:3000,
              message: res.message
            })
          }
        } catch (error) {
        // 处理可能出现的错误
        console.error('收藏出错', error);
      }
    },

  },
  created() {
    this.getDetail()
    // this.getDep()
    // console.log(this.queInfo,'create que')
  },
  components: {
    Head: head,
    Comment: comments,
    Foot:foot
  },
  watch: {
    queId(newId) {
      // this.queInfo = null
      this.queId = newId
      this.getDetail()
      this.getDep()
      // console.log(this.queInfo,' que')
    }
  },
  // computed: {
  //  queId: function(){
  //   return this.getDetail()
  //  }
  // }
}
</script>

<style lang="less" scoped>
li {
  list-style: none;
}

a {
  text-decoration: none;
}

.info {
  width: 90%;
  margin: .5rem auto;

  .back {
    float: left;

    span {
      display: inline-block;
      text-align: left;
      height: 37px;
      line-height: 37px;
      font-size: 14px;
      cursor: pointer;
      color: #999aaa;
      padding-left: .1333rem;

    }

    span:hover {
      color: #ff3535
    }

    em {
      margin: .0833rem;
    }
  }

  h1 {
    text-align: center;
    padding-bottom: 20px;
    font-size: .5rem;
    margin: 5px 0;

  }

  .bar {
    width: 100%;
    border-top: 1px solid #dcdcdc;

    ul {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 0;

      li {
        font-size: 14px;
        padding: 10px 20px 0 0;
        color: #585858;
      }
    }
  }


}

.content {
  width: 90%;
  margin: 10px auto 10px;
  font-size: 18px;

  p {
    line-height: 40px;
    text-indent: 1em;
  }
}
.tag {
    display: inline-block;
    padding: 4px 10px; /* 内边距，可以根据需要调整 */
    background-color: #4CAF50; /* 绿色背景 */
    color: red; /* 文字颜色 */
    border-radius: 16px; /* 圆角，让边框看起来像长圆框 */
    border: 2px solid #4CAF50; /* 边框颜色和宽度，这里设置为与背景色相同，以形成完整的效果 */
    font-size: 14px; /* 字体大小 */
    line-height: 1; /* 保持文本垂直居中 */
    // margin-left: 70px;
}
.action-bar {
  display: flex;
  /* 改为justify-content: flex-start; 以使按钮靠左对齐 */
  justify-content: flex-start;
  /* 添加间距以与左侧对齐，可根据需要调整像素值 */
  padding-left: 1.5rem;
  /* 移除按钮之间的默认间隔，实现紧密排布 */
  gap: 0;
}

.action-bar button {
  background-color: #cfe8b5;
  /* 初始浅绿色 */
  color: #333;
  /* 文字颜色 */
  border: none;
  /* 移除边框 */
  padding: 0.5rem 1rem;
  /* 内边距调整大小 */
  font-size: 14px;
  /* 字体大小 */
  cursor: pointer;
  /* 鼠标悬停时显示手型 */
  transition: background-color 0.3s ease;
  /* 过渡效果 */
  border-radius: 4px;
  /* 圆角 */
}

.action-bar button:hover {
  background-color: #9ccc65;
  /* 鼠标悬停时颜色加深 */
}

.action-bar button:active {
  background-color: #66bb6a;
  /* 点击时变为深绿色 */
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  /* 添加点击阴影效果 */
}

.action-bar .count {
  margin-left: 4px;
  /* 或根据需要调整 */
  font-weight: bold;
}
</style>
