import request from '../until/request.js'

// 获取文章评论
export const getComments = data => {
  return request.post('/api/getComments', data)
}

// 发表评论
export const addComment = data => {
  return request.post('/admin/addComment', data)
}

// 删除评论
export const delComment = data => {
  return request.post('/admin/delComment', data)
}
